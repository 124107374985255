<template>
   <div class="tag" v-if="showTags">
      <div>
         <ul>
            <li v-for="(item, index) in tagsList" :class="{ active: isActive(item.path) }" :key="index">
               <router-link :to="item.path" class="tags-li-title">{{
                  item.title
                  }}</router-link>
               <svg @click="closeTags(index)" style="width: 12px; margin-left: 5px;" viewBox="0 0 1024 1024"
                  xmlns="http://www.w3.org/2000/svg" data-v-ba633cb8="">
                  <path fill="currentColor"
                     d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                  </path>
               </svg>
            </li>
         </ul>
      </div>
      <div class="aa">
         <el-dropdown size="small" @command="handleTags">
            <div class="options">
               标签选项
               <svg style="width: 12px; margin-left: 5px;" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                  data-v-ba633cb8="">
                  <path fill="currentColor"
                     d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z">
                  </path>
               </svg>
            </div>
            <template #dropdown>
               <el-dropdown-menu>
                  <el-dropdown-item command="other">关闭其它</el-dropdown-item>
                  <el-dropdown-item command="all">关闭所有</el-dropdown-item>
               </el-dropdown-menu>
            </template>
         </el-dropdown>
      </div>
   </div>
</template>
<script>
import { computed, inject } from "vue";
import { useStore } from "vuex";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
export default {
   setup () {
      const reload = inject('reload')
      const route = useRoute();
      const router = useRouter();
      const isActive = (path) => {
         return path === route.fullPath;
      };

      const store = useStore();
      const tagsList = computed(() => store.state.tagsList);
      const showTags = computed(() => tagsList.value.length > 0);
      // 关闭单个标签
      const closeTags = (index) => {
         // console.log(index)

         const delItem = tagsList.value[index];
         console.log(delItem)
         if (tagsList.value[index].allow == 1) {
            store.commit("delTagsItem", { index });
            const item = tagsList.value[index] ? tagsList.value[index] : tagsList.value[index - 1];
            if (item) {
               delItem.path === route.fullPath && router.push(item.path);
               // console.log(tagsList)
               // console.log(item)
            } else {
               router.push("/");
            }
         }

      };

      // 设置标签
      const setTags = (routes) => {
         // console.log(route)
         const isExist = tagsList.value.some((item) => {
            return item.path === routes.fullPath;
         });
         if (!isExist) {
            if (tagsList.value.length >= 8) {
               store.commit("delTagsItem", { index: 0 });
            }
            store.commit("setTagsItem", {
               name: routes.name,
               title: routes.meta.title,
               path: routes.fullPath,
               active: routes.meta.active,
               allow: 1
            });
         }
      };
      setTags(route);
      onBeforeRouteUpdate((to) => {
         setTags(to);
         // console.log(to)
      });

      // 关闭全部标签
      const closeAll = () => {
         store.commit("clearTags");
         router.push("/");
      };

      // 关闭其他标签
      const closeOther = () => {
         const curItem = tagsList.value.filter((item) => {
            return item.path === route.fullPath;
         });
         store.commit("closeTagsOther", curItem);
      };
      const handleTags = (command) => {
         command === "other" ? closeOther() : closeAll();
      };

      return {
         isActive,
         tagsList,
         showTags,
         closeTags,
         handleTags,
      };
   },
};
</script>
<style lang="scss">
@media screen and (max-width: 490px) {
   .tag li {
      display: none !important;
   }
}

a {
   text-decoration: none;
   color: #000;
}

.router-link-active {
   text-decoration: none;
   color: #ffffff;
}

.active {
   border: 1px solid #409eff !important;
   background-color: #409eff !important;
   color: #ffffff !important;
}

::v-deep .el-dropdown-menu__item {
   display: flex;
   justify-content: center;
   font-size: 14px;
   height: 40px;
}

.el-dropdown-menu {
   width: 100px;
}

.options {
   width: 100px;
   height: 28px;
   border-radius: 5px;
   background-color: #409eff;
   color: #ffffff;
   line-height: 28px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 12px;
}

.aa {
   // position: absolute;
   // right: 0;
   box-sizing: border-box;
   padding-top: 1px;
   text-align: center;
   width: 110px;
   height: 30px;
   background: #fff;
   box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
   z-index: 10;
}

.tag ul {
   box-sizing: border-box;
   width: 100%;
   height: 100%;
   overflow-x: auto;
}

.tag ul::-webkit-scrollbar {
   display: none;
}

.tag li {
   float: left;
   margin: 3px 5px 2px 3px;
   border-radius: 3px;
   font-size: 12px;
   overflow: hidden;
   cursor: pointer;
   height: 23px;
   line-height: 23px;
   border: 1px solid #e9eaec;
   background: #fff;
   padding: 0 5px 0 12px;
   vertical-align: middle;
   color: #666;
   -webkit-transition: all 0.3s ease-in;
   -moz-transition: all 0.3s ease-in;
   transition: all 0.3s ease-in;
   display: flex;
   flex-direction: row;
   align-items: center;
}

.tag {
   display: flex;
   justify-content: space-between;
   height: 30px;
   background: #fff;
   box-shadow: 0 5px 10px #ddd;
   z-index: 100;
}

.tag-close-box {
   position: absolute;
   right: 0;
   top: 0;
   box-sizing: border-box;
   padding-top: 1px;
   text-align: center;
   width: 110px;
   height: 30px;
   background: #fff;
   box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
   z-index: 10;
}
</style>