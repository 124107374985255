import { createRouter, createWebHashHistory } from 'vue-router'
import Leftside from '../components/leftside.vue'

let routes = [
   {
      path: "/",
      redirect: "/homepage",
   },
   {
      path: '/',
      name: 'Leftside',
      component: Leftside,
      meta: {
         requireAuth: true,
      },
      children: [
         {
            path: "/homepage",
            name: "homepage",
            meta: {
               active: '/homepage',
               title: "系统首页",
            },
            component: () => import("../views/homepage.vue"),
         },
         {
            path: "/branch",
            name: "branch",
            meta: {
               active: '/branch',
               title: "组织管理",
            },
            component: () => import("../views/branch.vue"),
         },
         {
            path: "/texture",
            name: "texture",
            meta: {
               active: '/branch',
               title: "组织添加",
            },
            component: () => import("../views/addition/texture.vue"),
         },
         {
            path: "/zzedit",
            name: "zzedit",
            meta: {
               active: '/branch',
               title: "组织编辑",
            },
            component: () => import("../views/compile/zzedit.vue"),
         },
         {
            path: "/member",
            name: "member",
            meta: {
               active: '/member',
               title: "会员管理",
            },
            component: () => import("../views/member.vue"),
         },
         {
            path: "/hyadd",
            name: "hyadd",
            meta: {
               active: '/member',
               title: "会员添加",
            },
            component: () => import("../views/addition/hyadd.vue"),
         },
         {
            path: "/HYchannel",
            name: "HYchannel",
            meta: {
               active: '/member',
               title: "会员导入",
            },
            component: () => import("../views/addition/HYchannel.vue"),
         },
         {
            path: "/HYunsuccessful",
            name: "HYunsuccessful",
            meta: {
               active: '/member',
               title: "会员导入失败日志",
            },
            component: () => import("../views/addition/HYunsuccessful.vue"),
         },
         {
            path: "/hyedit",
            name: "hyedit",
            meta: {
               active: '/member',
               title: "会员编辑",
            },
            component: () => import("../views/compile/hyedit.vue"),
         },
         {
            path: "/dynamic",
            name: "dynamic",
            meta: {
               active: '/dynamic',
               title: "工会动态",
            },
            component: () => import("../views/dynamic/dynamic.vue"),
         },
         {
            path: "/inform",
            name: "inform",
            meta: {
               active: '/dynamic',
               title: "动态添加",
            },
            component: () => import("../views/dynamic/inform.vue"),
         },
         {
            path: "/dtedit",
            name: "dtedit",
            meta: {
               active: '/dynamic',
               title: "动态编辑",
            },
            component: () => import("../views/compile/dtedit.vue"),
         },
         {
            path: "/advanced",
            name: "advanced",
            meta: {
               active: '/advanced',
               title: "先进典型",
            },
            component: () => import("../views/advanced.vue"),
         },
         {
            path: "/xjadd",
            name: "xjadd",
            meta: {
               active: '/advanced',
               title: "典型添加",
            },
            component: () => import("../views/addition/xjadd.vue"),
         },
         {
            path: "/xjedit",
            name: "xjedit",
            meta: {
               active: '/advanced',
               title: "典型编辑",
            },
            component: () => import("../views/compile/xjedit.vue"),
         },
         {
            path: "/xjmanage",
            name: "xjmanage",
            meta: {
               active: '/advanced',
               title: "典型列表",
            },
            component: () => import("../views/manage/xjmanage.vue"),
         },
         {
            path: "/dxgladd",
            name: "dxgladd",
            meta: {
               active: '/advanced',
               title: "管理添加",
            },
            component: () => import("../views/addition/dxgladd.vue"),
         },
         {
            path: "/dxgledit",
            name: "dxgledit",
            meta: {
               active: '/advanced',
               title: "列表编辑",
            },
            component: () => import("../views/compile/dxgledit.vue"),
         },
         {
            path: "/xjdetails",
            name: "xjdetails",
            meta: {
               active: '/advanced',
               title: "列表详情",
            },
            component: () => import("../views/manage/xjdetails.vue"),
         },
         {
            path: "/answer",
            name: "answer",
            meta: {
               active: '/answer',
               title: "答题问卷",
            },
            component: () => import("../views/answer.vue"),
         },
         {
            path: "/wjadd",
            name: "wjadd",
            meta: {
               active: '/answer',
               title: "问卷添加",
            },
            component: () => import("../views/addition/wjadd.vue"),
         },
         {
            path: "/wjedit",
            name: "wjedit",
            meta: {
               active: '/answer',
               title: "问卷编辑",
            },
            component: () => import("../views/compile/wjedit.vue"),
         },
         {
            path: "/eventRegistration",
            name: "eventRegistration",
            meta: {
               active: '/eventRegistration',
               title: "日常活动举办",
            },
            component: () => import("../views/eventRegistration.vue"),
         },
         {
            path: "/bmadd",
            name: "bmadd",
            meta: {
               active: '/eventRegistration',
               title: "活动添加",
            },
            component: () => import("../views/addition/bmadd.vue"),
         },
         {
            path: "/bmedit",
            name: "bmedit",
            meta: {
               active: '/eventRegistration',
               title: "活动编辑",
            },
            component: () => import("../views/compile/bmedit.vue"),
         },
         {
            path: "/bmdetail",
            name: "bmdetail",
            meta: {
               active: '/eventRegistration',
               title: "报名查看",
            },
            component: () => import("../views/dynamic/bmdetail.vue"),
         },
         {
            path: "/jmdetail",
            name: "jmdetail",
            meta: {
               active: '/eventRegistration',
               title: "作品设置",
            },
            component: () => import("../views/dynamic/jmdetail.vue"),
         },
         {
            path: "/jmadd",
            name: "jmadd",
            meta: {
               active: '/jmdetail',
               title: "作品添加",
            },
            component: () => import("../views/addition/jmadd.vue"),
         },
         {
            path: "/jmedit",
            name: "jmedit",
            meta: {
               active: '/jmdetail',
               title: "作品编辑",
            },
            component: () => import("../views/compile/jmedit.vue"),
         },
         {
            path: "/dfdetail",
            name: "dfdetail",
            meta: {
               active: '/pfdetail',
               title: "评分查看",
            },
            component: () => import("../views/dynamic/dfdetail.vue"),
         },
         {
            path: "/pfdetail",
            name: "pfdetail",
            meta: {
               active: '/eventRegistration',
               title: "评分结果",
            },
            component: () => import("../views/dynamic/pfdetail.vue"),
         },
         {
            path: "/walk",
            name: "walk",
            meta: {
               active: '/walk',
               title: "健步运动",
            },
            component: () => import("../views/walk.vue"),
         },
         {
            path: "/dailyStatistics",
            name: "dailyStatistics",
            meta: {
               active: '/walk',
               title: "按日统计",
            },
            component: () => import("../views/dynamic/dailyStatistics.vue"),
         },
         {
            path: "/jbedit",
            name: "jbedit",
            meta: {
               active: '/walk',
               title: "健步查看",
            },
            component: () => import("../views/compile/jbedit.vue"),
         },
         {
            path: "/personage",
            name: "personage",
            meta: {
               title: "修改密码",
            },
            component: () => import("../views/personage.vue"),
         },
         {
            path: "/pigeonhole",
            name: "pigeonhole",
            meta: {
               active: '/member',
               title: "离职归档",
            },
            component: () => import("../views/dynamic/pigeonhole.vue"),
         },
         {
            path: "/Hydetails",
            name: "Hydetails",
            meta: {
               active: '/member',
               title: "会员详情",
            },
            component: () => import("../views/dynamic/Hydetails.vue"),
         },
         {
            path: "/DTdetails",
            name: "DTdetails",
            meta: {
               active: '/dynamic',
               title: "动态详情",
            },
            component: () => import("../views/dynamic/DTdetails.vue"),
         },
         {
            path: "/statistics",
            name: "statistics",
            meta: {
               active: '/answer',
               title: "会员统计",
            },
            component: () => import("../views/manage/statistics.vue"),
         },
         {
            path: "/substance",
            name: "substance",
            meta: {
               active: '/answer',
               title: "答题内容",
            },
            component: () => import("../views/manage/substance.vue"),
         },
         {
            path: "/consequence",
            name: "consequence",
            meta: {
               active: '/answer',
               title: "数据分析",
            },
            component: () => import("../views/manage/consequence.vue"),
         },
         {
            path: "/question",
            name: "question",
            meta: {
               active: '/answer',
               title: "简答详情",
            },
            component: () => import("../views/manage/question.vue"),
         },
         {
            path: "/ZNtrustee",
            name: "ZNtrustee",
            meta: {
               active: '/ZNtrustee',
               title: "子女托管",
            },
            component: () => import("../views/ZNtrustee.vue"),
         },
         {
            path: "/ZNadd",
            name: "ZNadd",
            meta: {
               active: '/ZNtrustee',
               title: "子女添加",
            },
            component: () => import("../views/addition/ZNadd.vue"),
         },
         {
            path: "/ZNexamine",
            name: "ZNexamine",
            meta: {
               active: '/ZNtrustee',
               title: "查看报名",
            },
            component: () => import("../views/manage/ZNexamine.vue"),
         },
         {
            path: "/ZNclasswide",
            name: "ZNclasswide",
            meta: {
               active: '/ZNtrustee',
               title: "班次管理",
            },
            component: () => import("../views/manage/ZNclasswide.vue"),
         },
         {
            path: "/ZNBJadd",
            name: "ZNBJadd",
            meta: {
               active: '/ZNtrustee',
               title: "班次添加",
            },
            component: () => import("../views/addition/ZNBJadd.vue"),
         },
         {
            path: "/ZNBJedit",
            name: "ZNBJedit",
            meta: {
               active: '/ZNtrustee',
               title: "班次编辑",
            },
            component: () => import("../views/compile/ZNBJedit.vue"),
         },
         {
            path: "/ZNexterior",
            name: "ZNexterior",
            meta: {
               active: '/ZNtrustee',
               title: "外部工会管理",
            },
            component: () => import("../views/manage/ZNexterior.vue"),
         },
         {
            path: "/ZNWBadd",
            name: "ZNWBadd",
            meta: {
               active: '/ZNtrustee',
               title: "外部工会添加",
            },
            component: () => import("../views/addition/ZNWBadd.vue"),
         },
         {
            path: "/ZNWBdit",
            name: "ZNWBdit",
            meta: {
               active: '/ZNtrustee',
               title: "外部工会编辑",
            },
            component: () => import("../views/compile/ZNWBdit.vue"),
         },
         {
            path: "/children",
            name: "children",
            meta: {
               active: '/ZNtrustee',
               title: "往期回顾",
            },
            component: () => import("../views/children.vue"),
         },
         {
            path: "/childrenadd",
            name: "childrenadd",
            meta: {
               active: '/ZNtrustee',
               title: "往期添加",
            },
            component: () => import("../views/addition/childrenadd.vue"),
         },
         {
            path: "/ZNBMredact",
            name: "ZNBMredact",
            meta: {
               active: '/ZNtrustee',
               title: "托管编辑",
            },
            component: () => import("../views/compile/ZNBMredact.vue"),
         },
         {
            path: "/ZNdit",
            name: "ZNdit",
            meta: {
               active: '/ZNtrustee',
               title: "往期编辑",
            },
            component: () => import("../views/compile/ZNdit.vue"),
         },
         {
            path: "/ZNxiangq",
            name: "ZNxiangq",
            meta: {
               active: '/ZNtrustee',
               title: "回顾详情",
            },
            component: () => import("../views/manage/ZNxiangq.vue"),
         },
         {
            path: "/doingThings",
            name: "doingThings",
            meta: {
               active: '/doingThings',
               title: "我为群众办实事",
            },
            component: () => import("../views/doingThings.vue"),
         },
         {
            path: "/ssadd",
            name: "ssadd",
            meta: {
               active: '/doingThings',
               title: "实事添加",
            },
            component: () => import("../views/addition/ssadd.vue"),
         },
         {
            path: "/ssedit",
            name: "ssedit",
            meta: {
               active: '/doingThings',
               title: "实事编辑",
            },
            component: () => import("../views/compile/ssedit.vue"),
         },
         {
            path: "/ssdetail",
            name: "ssdetail",
            meta: {
               active: '/doingThings',
               title: "查看领取名单",
            },
            component: () => import("../views/dynamic/ssdetail.vue"),
         },
         {
            path: "/gmdetail",
            name: "gmdetail",
            meta: {
               active: '/doingThings',
               title: "查看购买名单",
            },
            component: () => import("../views/dynamic/gmdetail.vue"),
         },
         {
            path: "/packageManagement",
            name: "packageManagement",
            meta: {
               active: '/doingThings',
               title: "套餐管理",
            },
            component: () => import("../views/dynamic/packageManagement.vue"),
         },
         {
            path: "/tcadd",
            name: "tcadd",
            meta: {
               active: '/packageManagement',
               title: "套餐添加",
            },
            component: () => import("../views/addition/tcadd.vue"),
         },
         {
            path: "/tcedit",
            name: "tcedit",
            meta: {
               active: '/packageManagement',
               title: "套餐编辑",
            },
            component: () => import("../views/compile/tcedit.vue"),
         },
         {
            path: "/couponManagement",
            name: "couponManagement",
            meta: {
               active: '/doingThings',
               title: "券管理",
            },
            component: () => import("../views/dynamic/couponManagement.vue"),
         },
         {
            path: "/failureLog",
            name: "failureLog",
            meta: {
               active: '/doingThings',
               title: "发放失败日志页",
            },
            component: () => import("../views/dynamic/failureLog.vue"),
         },
         {
            path: "/elegant",
            name: "elegant",
            meta: {
               active: '/elegant',
               title: "活动风采",
            },
            component: () => import("../views/elegant.vue"),
         },
         {
            path: "/HDadd",
            name: "HDadd",
            meta: {
               active: '/elegant',
               title: "活动添加",
            },
            component: () => import("../views/addition/HDadd.vue"),
         },
         {
            path: "/FCdit",
            name: "FCdit",
            meta: {
               active: '/elegant',
               title: "活动编辑",
            },
            component: () => import("../views/compile/FCdit.vue"),
         },
         {
            path: "/FCdetails",
            name: "FCdetails",
            meta: {
               active: '/elegant',
               title: "活动详情",
            },
            component: () => import("../views/manage/FCdetails.vue"),
         },
         {
            path: "/payment",
            name: "payment",
            meta: {
               active: '/payment',
               title: "会费缴纳",
            },
            component: () => import("../views/payment.vue"),
         },
         {
            path: "/HFruleadd",
            name: "HFruleadd",
            meta: {
               active: '/payment',
               title: "添加规则",
            },
            component: () => import("../views/manage/HFruleadd.vue"),
         },
         {
            path: "/HFruledit",
            name: "HFruledit",
            meta: {
               active: '/payment',
               title: "编辑规则",
            },
            component: () => import("../views/compile/HFruledit.vue"),
         },
         {
            path: "/HFset",
            name: "HFset",
            meta: {
               active: '/payment',
               title: "设置缴费",
            },
            component: () => import("../views/manage/HFset.vue"),
         },
         {
            path: "/HFamount",
            name: "HFamount",
            meta: {
               active: '/payment',
               title: "设置缴费金额",
            },
            component: () => import("../views/manage/HFamount.vue"),
         },
         {
            path: "/HFmember",
            name: "HFmember",
            meta: {
               active: '/payment',
               title: "会费操作日志",
            },
            component: () => import("../views/manage/HFmember.vue"),
         },
         {
            path: "/HFargu",
            name: "HFargu",
            meta: {
               active: '/HFargu',
               title: "支付管理",
            },
            component: () => import("../views/manage/HFargu.vue"),
         },
         {
            path: "/TYargu",
            name: "TYargu",
            meta: {
               active: '/TYargu',
               title: "管理员管理",
            },
            component: () => import("../views/manage/TYargu.vue"),
         },
         {
            path: "/SZargur",
            name: "SZargur",
            meta: {
               active: '/TYargu',
               title: "设置管理",
            },
            component: () => import("../views/manage/SZargur.vue"),
         },
         {
            path: "/LLargu",
            name: "LLargu",
            meta: {
               active: '/LLargu',
               title: "管理员登录日志",
            },
            component: () => import("../views/XTanage/LLargu.vue"),
         },
         {
            path: "/HFarguadd",
            name: "HFarguadd",
            meta: {
               active: '/HFargu',
               title: "添加缴费参数",
            },
            component: () => import("../views/manage/HFarguadd.vue"),
         },
         {
            path: "/HFargudit",
            name: "HFargudit",
            meta: {
               active: '/HFargu',
               title: "编辑缴费参数",
            },
            component: () => import("../views/compile/HFargudit.vue"),
         },
         {
            path: "/HFregulation",
            name: "HFregulation",
            meta: {
               active: '/payment',
               title: "设置规则",
            },
            component: () => import("../views/manage/HFregulation.vue"),
         },
         {
            path: "/HFrecord",
            name: "HFrecord",
            meta: {
               active: '/payment',
               title: "会费缴纳记录",
            },
            component: () => import("../views/manage/HFrecord.vue"),
         },
         {
            path: "/HFguide",
            name: "HFguide",
            meta: {
               active: '/payment',
               title: "导入失败日志",
            },
            component: () => import("../views/manage/HFguide.vue"),
         },
         {
            path: "/xiaoxiang",
            name: "xiaoxiangIndex",
            meta: {
               active: '/xiaoxiang',
               title: "小象超市",
            },
            component: () => import("../views/xiaoxiangSupermarket/index.vue"),
         },
         {
            path: "/xiaoxiang/refundDetail",
            name: "xiaoxiangrefundDetail",
            meta: {
               active: '/xiaoxiang',
               title: "小象超市-退款查看",
            },
            component: () => import("../views/xiaoxiangSupermarket/refundDetail.vue"),
         },
         {
            path: "/xiaoxiang/goodsDetail",
            name: "xiaoxiangGoodsDetail",
            meta: {
               title: "小象超市-商品查看",
               active: '/xiaoxiang',
            },
            component: () => import("../views/xiaoxiangSupermarket/goodsDetail.vue"),
         },
         {
            path: "/JFstatistics",
            name: "JFstatistics",
            meta: {
               active: '/JFstatistics',
               title: "积分管理",
            },
            component: () => import("../views/JFmanage/JFstatistics.vue"),
         },
         {
            path: "/JFdefeated",
            name: "JFdefeated",
            meta: {
               active: '/JFstatistics',
               title: "派发失败日志",
            },
            component: () => import("../views/JFmanage/JFdefeated.vue"),
         },
         {
            path: "/JFpurchase",
            name: "JFpurchase",
            meta: {
               active: '/JFstatistics',
               title: "购买积分日志",
            },
            component: () => import("../views/JFmanage/JFpurchase.vue"),
         },
         {
            path: "/JFreturn",
            name: "JFreturn",
            meta: {
               active: '/JFstatistics',
               title: "积分返还日志",
            },
            component: () => import("../views/JFmanage/JFreturn.vue"),
         },
         {
            path: "/JFrecall",
            name: "JFrecall",
            meta: {
               active: '/JFstatistics',
               title: "积分回收",
            },
            component: () => import("../views/JFmanage/JFrecall.vue"),
         },
         {
            path: "/JFfail",
            name: "JFfail",
            meta: {
               active: '/JFstatistics',
               title: "回收失败日志",
            },
            component: () => import("../views/JFmanage/JFfail.vue"),
         },
         {
            path: "/JFdeduct",
            name: "JFdeduct",
            meta: {
               active: '/JFstatistics',
               title: "积分代扣",
            },
            component: () => import("../views/JFmanage/JFdeduct.vue"),
         },
         {
            path: "/JFexceed",
            name: "JFexceed",
            meta: {
               active: '/JFstatistics',
               title: "积分过期",
            },
            component: () => import("../views/JFmanage/JFexceed.vue"),
         },
         {
            path: "/JFmanual",
            name: "JFmanual",
            meta: {
               active: '/JFstatistics',
               title: "手动过期",
            },
            component: () => import("../views/JFmanage/JFmanual.vue"),
         },
         {
            path: "/JFlose",
            name: "JFlose",
            meta: {
               active: '/JFstatistics',
               title: "代扣失败日志",
            },
            component: () => import("../views/JFmanage/JFlose.vue"),
         },
         {
            path: "/YEremaining",
            name: "YEremaining",
            meta: {
               active: '/YEremaining',
               title: "余额统计",
            },
            component: () => import("../views/JFmanage/YEremaining.vue"),
         },
         {
            path: "/YEintegral",
            name: "YEintegral",
            meta: {
               active: '/YEremaining',
               title: "积分明细",
            },
            component: () => import("../views/JFmanage/YEintegral.vue"),
         },
         {
            path: "/YEticket",
            name: "YEticket",
            meta: {
               active: '/YEremaining',
               title: "提货券明细",
            },
            component: () => import("../views/JFmanage/YEticket.vue"),
         },
         {
            path: "/XFstatistics",
            name: "XFstatistics",
            meta: {
               name: 'XFstatistics',
               active: '/XFstatistics',
               title: "消费统计",
            },
            component: () => import("../views/JFmanage/XFstatistics.vue"),
         },
         {
            path: "/associationManagement",
            name: "associationManagement",
            meta: {
               active: '/associationManagement',
               title: "协会管理",
            },
            component: () => import("../views/Association/associationManagement.vue"),
         },
         {
            path: "/associationAdd",
            name: "associationAdd",
            meta: {
               active: '/associationManagement',
               title: "协会添加",
            },
            component: () => import("../views/Association/associationAdd.vue"),
         },
         {
            path: "/associationEdit",
            name: "associationEdit",
            meta: {
               active: '/associationManagement',
               title: "协会编辑",
            },
            component: () => import("../views/Association/associationEdit.vue"),
         },
         {
            path: "/registrationStatistics",
            name: "registrationStatistics",
            meta: {
               active: '/associationManagement',
               title: "报名统计",
            },
            component: () => import("../views/Association/registrationStatistics.vue"),
         },
         {
            path: "/associationAdministrator",
            name: "associationAdministrator",
            meta: {
               active: '/associationManagement',
               title: "协会管理员",
            },
            component: () => import("../views/Association/associationAdministrator.vue"),
         },
         {
            path: "/membershipStatistics",
            name: "membershipStatistics",
            meta: {
               active: '/membershipStatistics',
               title: "会员协会统计",
            },
            component: () => import("../views/Association/membershipStatistics.vue"),
         },
         {
            path: "/manualExtension",
            name: "manualExtension",
            meta: {
               active: '/registrationStatistics',
               title: "手动延期",
            },
            component: () => import("../views/Association/manualExtension.vue"),
         },
         {
            path: "/delayLog",
            name: "delayLog",
            meta: {
               active: '/registrationStatistics',
               title: "延期日志",
            },
            component: () => import("../views/Association/delayLog.vue"),
         },
         {
            path: "/WQlook",
            name: "WQlook",
            meta: {
               active: '/WQlook',
               title: "往期回顾",
            },
            component: () => import("../views/Association/WQlook.vue"),
         },
         {
            path: "/WQaddlook",
            name: "WQaddlook",
            meta: {
               active: '/WQlook',
               title: "往期添加",
            },
            component: () => import("../views/Association/WQaddlook.vue"),
         },
         {
            path: "/WQedit",
            name: "WQedit",
            meta: {
               active: '/WQlook',
               title: "往期编辑",
            },
            component: () => import("../views/Association/WQedit.vue"),
         },
         {
            path: "/process",
            name: "process",
            meta: {
               active: '/process',
               title: "流程管理",
            },
            component: () => import("../views/process.vue"),
         },
         {
            path: "/processadd",
            name: "processadd",
            meta: {
               active: '/process',
               title: "流程添加",
            },
            component: () => import("../views/addition/processadd.vue"),
         },
         {
            path: "/processedit",
            name: "processedit",
            meta: {
               active: '/process',
               title: "流程编辑",
            },
            component: () => import("../views/addition/processedit.vue"),
         },
         {
            path: "/smsPush",
            name: "smsPush",
            meta: {
               active: '/smsPush',
               title: "短信推送管理",
            },
            component: () => import("../views/manage/smsPush.vue"),
         },
         {
            path: "/manualOperation",
            name: "manualOperation",
            meta: {
               active: '/smsPush',
               title: "手动操作",
            },
            component: () => import("../views/manage/manualOperation.vue"),
         }
      ]
   },
   {
      path: "/Login",
      name: "Login",
      component: () => import("../views/login.vue"),
   },
]

let router = createRouter({
   // history: createWebHistory(process.env.BASE_URL),
   history: createWebHashHistory(process.env.BASE_URL),
   routes
})
router.beforeEach((to, from, next) => {
   // console.log(to)
   // console.log(from)
   if (to.matched.some((route) => route.meta.requireAuth)) {
      if (sessionStorage.getItem("token")) {
         // 判断用户是否登录，登录之后会存用户相关的信息
         next();
      } else {
         // next({
         //   path: `/Login`,
         //   query: { redirect: to.fullPath },
         // });
         if (to.path == '/Login') { //如果是登录页面路径，就直接next()
            next();
         } else { //不然就跳转到登录；
            next('/Login');
         }
      }
   } else {
      next(); // 确保一定要调用 next()
   }
   if (to.name === 'Login') {
      if (sessionStorage.getItem("token")) {
         router.push({ path: '/homepage' });
      }
   }
});

export default router
